<template>
  <div name="devices" class="d-flex flex-column" :class="{ 'has-search-result': device }">
    <v-row ref="toolbar" no-gutters class="toolbar pa-4 light-blue lighten-2" justify="start" align="center">
      <div class="flex-grow-1 px-12">
        <v-row no-gutters justify="center">
          <v-text-field
            ref="searchTextField"
            class="search-textfield font-weight-medium"
            placeholder="Type Device ID then press ENTER"
            height="56"
            solo
            flat
            dense
            hide-details
            clearable
            autofocus
            prepend-inner-icon="mdi-magnify"
            :loading="busy"
            v-model="searchInput"
            @input="clearResults"
            @click:clear="searchInput = ''"
            @keypress.enter="searchForDevice()"
          ></v-text-field>
        </v-row>
      </div>
    </v-row>
    <v-slide-y-transition>
      <v-row v-if="showResult && !busy && device" no-gutters class="pa-5">
        <v-col cols="12" offset-md="3" md="6">
          <v-alert dark border="left" color="secondary" width="100%" :class="{ assigned: !!device.patientId }">
            <v-row no-gutters>
              <v-col cols="1" class="mx-2">
                <v-icon x-large class="center">mdi-digital-ocean</v-icon>
              </v-col>
              <v-col class="pl-3">
                <v-row no-gutters>
                  <span class="title text-capitalize">{{ device.kind }}</span>
                  <v-spacer></v-spacer>
                  <v-row no-gutters v-if="device.patientId" align="center" justify="end">
                    <v-icon color="light-green accent-4">mdi-circle-medium</v-icon>
                    <span class="font-weight-bold">Assigned</span>
                  </v-row>
                  <v-row no-gutters v-else align="center" justify="end">
                    <v-icon color="red">mdi-circle-medium</v-icon>
                    <span class="font-weight-bold">Unassigned</span>
                  </v-row>
                </v-row>
                <div class="device-id primary px-3 py-1 mt-2 rounded">
                  DEVICE ID: <span>{{ device.id }}</span>
                </div>
                <div class="device-id primary px-3 py-1 mt-2 rounded">
                  ORG: <span>{{ deviceOrg }}</span>
                </div>
                <div class="device-id primary px-3 py-1 mt-2 rounded">
                  PT: <span>{{ devicePt }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-spacer></v-spacer>
              <v-btn
                v-if="device.orgId"
                depressed
                dark
                color="button"
                class="px-4 mx-4"
                :loading="busyButton"
                @click="replaceDialogOpen = true"
                >
                Replace
              </v-btn>
              <v-btn
                v-if="device.orgId"
                depressed
                dark
                color="button"
                class="px-4 mx-4"
                :loading="busyButton"
                @click="unassignDeviceFromOrg"
                >
                Unassign from Org and Pt
              </v-btn>
              <v-btn
                v-if="device.patientId"
                depressed
                dark
                color="button"
                class="px-4 mx-4"
                :loading="busyButton"
                @click="unassignDeviceFromPatient"
                >
                Unassign from Pt
              </v-btn>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-slide-y-transition>
    <div class="text-center">
    <!-- Replace device dialog -->
    <v-dialog
      v-model="replaceDialogOpen"
      width="500"
      @click:outside="replaceOnClose"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Replace Device
        </v-card-title>

        <v-card-text>
          Replace this device with a new device. New device will be assigned to the same org and patient as the current device.
          Since the replacement device is not a new device order for the org, the replacement device will assume the current
          device's assignment date.
        </v-card-text>
        <v-form v-model="replaceFormValid">
          <!-- Device Kind Selector -->
          <v-select
            outlined
            background-color="white"
            :items="replaceKindSelections"
            placeholder="Select Device Kind"
            hide-details
            class="mr-2"
            style="max-width: 320px"
            v-model="replaceKind"
            :disabled="replaceBusy"
            :rules="[v => !!v || 'Must select device kind']"
            @change="replaceRefId = ''"
          ></v-select>

          <!-- Device ID field -->
          <v-text-field
            class="search-textfield font-weight-medium"
            height="56"
            solo
            flat
            hide-details
            clearable
            :disabled="!replaceKind || replaceBusy"
            :loading="replaceBusy"
            :rules="[v => !!v || 'Device ID required', v => !replaceKind || replaceKind.idValidation(v) || 'invalid']"
            :placeholder="replaceKind ? replaceKind.idLabel : 'Device ID'"
            v-model="replaceRefId"
            @click:clear="replaceRefId = ''"
            @keypress.enter="replaceOnSubmit"
          ></v-text-field>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="replaceOnClose"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            :disabled="!replaceFormValid || replaceBusy "
            @click="replaceOnSubmit"
          >
            Replace
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
[name='devices'] {
  height: 100%;
  transition: height 0.3s;
  transition-delay: 200ms;

  .toolbar {
    margin-top: -10%;
    transition: margin-top 0.3s;
    transition-delay: 200ms;
  }

  &.has-search-result {
    height: 80px;

    .toolbar {
      margin-top: 0;
    }
  }

  .search-textfield {
    max-width: 600px;
    background: #1e88e53a !important;
  }

  .v-input > .v-input__control {
    min-height: 56px;
  }

  .device-id {
    font-family: 'Courier New', Courier, monospace !important;
    font-weight: bold;
    font-size: 13px;
  }

  .v-alert__border {
    border-color: #f41303 !important;
    opacity: 1;
  }

  .v-alert.assigned .v-alert__border {
    border-color: #0bf403 !important;
    opacity: 1;
  }

  .enrollment-indicator {
    position: absolute;
    left: 0;
    width: 7px;
    height: 100%;
    background: #f41303;
  }

  .enrollment-indicator.enrolled {
    background: #0bf403;
  }
}
</style>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { state as adminState, getDevice, clearDevice, unassignDevice, replaceDevice } from '../services/admin'
import { state as patientState, getPatient, clearPatient } from '../shared-ui/store/patient'
import { state as orgState } from '../shared-ui/store/org'
import { broadcastSnackbar } from '../shared-ui/hooks'
import { DEVICES } from '../consts'
import { strcmp } from '../helpers/str'

export default defineComponent({
  setup() {
    const busy = ref(false)
    const busyButton = ref(false)
    const searchInput = ref('')
    const orgs = computed(() => orgState.orgs.sort((a, b) => strcmp(a.name, b.name)))
    const orgsMap = computed(() => Object.assign({}, ...orgs.value.map(x => ({[x.id]: x}))))
    const showResult = ref(false)

    const searchForDevice = async doneHandler => {
      busy.value = !doneHandler
      if (!searchInput.value) {
        if (doneHandler) {
          doneHandler()
        }
        busy.value = false
        return
      }

      let device
      try {
        device = await getDevice(searchInput.value)
      } catch(e) {
        broadcastSnackbar({
          icon: 'mdi-alert',
          text: 'Error looking up device: ' + e,
          showClose: true,
        })
        busy.value = false
        return
      }
      if (device?.patientId) {
        await getPatient(device.patientId)
      }
      if (adminState.device) {
        setTimeout(() => {
          showResult.value = true
        }, 500)
      } else {
        broadcastSnackbar({
          icon: 'mdi-alert',
          text: 'Device not found',
          showClose: true,
        })
      }

      if (doneHandler) doneHandler()
      busy.value = false
    }

    const device = computed(() => {
      if (adminState.device) {
        return adminState.device
      }
      return null
    })
    const deviceOrg = computed(() => {
      if (!device.value?.orgId) return ''
      const o = orgsMap.value[device.value.orgId]
      return `${o.name} (id:${o.id} assigned:${device.value.orgAssignedTs || ''})`
    })
    const devicePt = computed(() => device.value?.patientId ? `${patient.value.firstName} ${patient.value.lastName} (id:${device.value.patientId} orgPtId:${patient.value.org?.patientId})` : '')

    const patient = computed(() => {
      if (patientState.patient) {
        return patientState.patient
      }
      return null
    })

    const clearResults = () => {
      clearPatient()
      clearDevice()
      showResult.value = false
    }

    const unassignDeviceFromPatient = async () => {
      busyButton.value = true
      await unassignDevice(device.value.id)
      searchInput.value = device.value.refId
      clearPatient()
      searchForDevice(() => {
        busyButton.value = false
      })
    }

    const unassignDeviceFromOrg = async () => {
      busyButton.value = true
      await unassignDevice(device.value.id, true)
      searchInput.value = device.value.refId
      clearPatient()
      searchForDevice(() => {
        busyButton.value = false
      })
    }

    const onReplaceCb = (replacementRefId) => {
      clearResults()
      searchInput.value = replacementRefId
      searchForDevice()
    }

    clearResults()

    return {
      adminState,
      busy,
      busyButton,
      device,
      deviceOrg,
      devicePt,
      orgs,
      orgsMap,
      patient,
      clearResults,
      searchForDevice,
      searchInput,
      showResult,
      unassignDeviceFromPatient,
      unassignDeviceFromOrg,
      ...setupReplaceDialog(device, onReplaceCb)
    }
  },
})

function setupReplaceDialog(deviceRef, onReplaceCb) {
  const replaceDialogOpen = ref(false)
  const replaceBusy = ref(false)
  const replaceFormValid = ref(false)
  const replaceKind = ref(null)
  const replaceKindSelections = computed(() => {
    const selections = []
    for (const k in DEVICES) {
      selections.push({text: k, value: DEVICES[k]})
    }
    return selections.sort((a, b) => strcmp(a.text, b.text))
  })
  const replaceRefId = ref('')

  const replaceOnSubmit = async () => {
    if (!replaceFormValid.value) return
    replaceBusy.value = true
    try {
      await replaceDevice(deviceRef.value.id, replaceKind.value.kind, replaceRefId.value)
      onReplaceCb(replaceRefId.value)
      replaceOnClose()
    } catch (e) {
      broadcastSnackbar({
        icon: 'mdi-alert',
        text: 'Error replacing device: ' + e,
        showClose: true,
      })
      replaceBusy.value = false
    }
  }

  const replaceOnClose = () => {
    replaceReset()
    replaceDialogOpen.value = false
  }

  const replaceReset = () => {
    replaceKind.value = null
    replaceRefId.value = ''
    replaceBusy.value = false
    replaceFormValid.value = false
  }

  return {
    replaceDialogOpen,
    replaceBusy,
    replaceFormValid,
    replaceKind,
    replaceKindSelections,
    replaceRefId,
    replaceOnClose,
    replaceOnSubmit,
  }
}

</script>
